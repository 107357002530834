<script>
import { emitSucces } from '@/utils/alerts';
import redirect from '@/utils/redirect';

export default {
  name: 'Logout',
  async created() {
    await this.$store.dispatch('user/LOGOUT_USER');
    redirect({ name: 'Home' });
    emitSucces('You were logged out');
  },
};
</script>
